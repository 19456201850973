var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("park-info-header"),
      _c("img", {
        staticClass: "contact",
        attrs: { src: require("../../assets/img/park/contact.png") }
      }),
      _c("img", {
        staticClass: "welcome",
        attrs: { src: require("../../assets/img/park/welcome.png") }
      }),
      _vm._m(0),
      _c("address-map"),
      _c("park-info-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "centertext" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("江苏南通人力资源服务产业园")
      ]),
      _c("div", { staticClass: "small-title" }, [
        _vm._v("JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK")
      ]),
      _c("div", { staticClass: "address" }, [
        _vm._v("江苏省南通市崇川区工农北路150号")
      ]),
      _c("div", { staticClass: "Telephone" }, [
        _vm._v("联系电话：19905134546 0513-81183816")
      ]),
      _c("div", { staticClass: "email" }, [_vm._v("邮箱：ntrlzycyy@126.com")]),
      _c("div", { staticClass: "Postalcode" }, [_vm._v("邮编 ：226000")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }