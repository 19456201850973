var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "base" }, [
      _c("p", { staticClass: "title" }, [_vm._v("江苏南通人力资源服务产业园")]),
      _c("p", { staticClass: "subtitle" }, [
        _vm._v("JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }