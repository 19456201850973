<template>
  <div class="base">
    <p class="title">江苏南通人力资源服务产业园</p>
    <p class="subtitle">JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK</p>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.base {
  padding: 15px;

  .title {
    font-weight: 700;
    font-size: 16.1px;
    font-family: 'Source Han Sans CN';
    line-height: 25px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 6.2px;
    font-family: 'Arial';
  }
}
</style>
